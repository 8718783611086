import React from "react";
import InstagramIcon from '@mui/icons-material/Instagram';


const Contact = () => {
  return (
    <div className="contact">
      <a target="blank" href="https://www.instagram.com/deryayildizartist/" ><InstagramIcon className="insta" /></a>
      <a target="blank" href="https://www.instagram.com/deryayildizartist/" className="iletisim">/deryayildizartist</a>
    </div>
  );
};

export default Contact;
