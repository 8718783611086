import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const myNavbar = () => {
  return (
    <div className="nav">
      <Navbar className="navbar" variant="dark">
        <Container>
          <Nav>
            <Nav.Link className="pad" href="/">derya yıldız art</Nav.Link>
            <Nav.Link className="pad" href="/">home</Nav.Link>
            <Nav.Link className="pad" href="/works">examples of works</Nav.Link>
            <Nav.Link className="pad" href="/contact">contact</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

export default myNavbar;
