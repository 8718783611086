import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation } from "swiper";

import slide_image_1 from "../assets/images/1.avif";
import slide_image_2 from "../assets/images/2.jpg";
import slide_image_3 from "../assets/images/3.avif";
import slide_image_4 from "../assets/images/4.avif";
import slide_image_5 from "../assets/images/5.avif";

const Works = () => {
  return (
    <div className="container">
      <h1 className="heading">gallery</h1>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centerslides="true"
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{el:".swiper-pagination",clickable:true}}
        navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
        }}
        modules={[EffectCoverflow,Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <img src={slide_image_1} alt="art" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_2} alt="art" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_3} alt="art" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_4} alt="art" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_5} alt="art" />
        </SwiperSlide>
        <div className="slider-controller">
            <div className="swiper-button-prev slider-arrow">
                <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow">
                <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
};

export default Works;
