import React from "react";

const Home = () => {
  return (
    <div>
      <div className="foto">
        <img src="derya.png" alt="art" />
      </div>
      <article>
        derya yıldız is an artist adopting the theme of modern women who has the
        ability of blending the contemporary life with harmony.
      </article>
    </div>
  );
};

export default Home;
